/* eslint-disable react/no-array-index-key */
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { palette, spectrum } from "styles/theme";

type Props = Omit<React.ComponentPropsWithoutRef<"table">, "title"> & {
  /**
   * Creates the content for the title after "Show" or "Hide"
   */
  title: React.ReactNode;

  /**
   * How many columns the show/hide should span
   */
  colSpan: number;
};

const BORDER = `solid 1px ${palette.border}`;
const BORDER_RADIUS = "14px";

// Reset some borders + padding so we can apply it to Content
const Table = styled.table<{ $isExpanded: boolean }>`
  &&& {
    border-collapse: separate;
    ${({ $isExpanded }) =>
      !$isExpanded &&
      css`
        white-space: nowrap;
        width: min-content;
        align-self: flex-start;
      `}
  }
  & tr {
    border: none;
  }
  & td {
    border-top: ${BORDER};
    padding: 16px 12px !important;
  }

  & td:first-child {
    border-left: ${BORDER};
  }
  & td:last-child {
    border-right: ${BORDER};
  }
  & tr:first-child td:first-child {
    border-top-left-radius: ${BORDER_RADIUS};
  }
  & tr:first-child td:last-child {
    border-top-right-radius: ${BORDER_RADIUS};
  }
  & tr:last-child td {
    border-bottom: ${BORDER};
  }
  & tr:last-child td:first-child {
    border-bottom-left-radius: ${BORDER_RADIUS};
  }
  & tr:last-child td:last-child {
    border-bottom-right-radius: ${BORDER_RADIUS};
  }
`;

const ShowHideContent = styled.td`
  && {
    padding: 0 !important;
  }
`;

// Gray small button with small padding
const ShowHideButton = styled.button`
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${spectrum.gray40};
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 4px 16px;
  &&& code {
    font-weight: 600;
    color: ${spectrum.gray40};
    background: none;
    padding: 0;
  }
`;

/**
 * Creates a `tbody` element that expands on click and shows data
 * inside its rows. Is indented to the left, as its meant to show
 * details from another tbody above it.
 */
const ExpandableTable = ({ title, colSpan, children, ...props }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const verb = isExpanded ? "Hide" : "Show";

  // For screen reader, if the enter key is pressed when this element has focus, handle it as a click
  const respondToKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setIsExpanded(!isExpanded);
    }
  };
  const chevron = isExpanded ? <ChevronUp size={12} /> : <ChevronDown size={12} />;

  return (
    <Table {...props} cellSpacing={0} cellPadding={0} $isExpanded={isExpanded}>
      <tbody>
        <tr>
          <ShowHideContent colSpan={colSpan}>
            <ShowHideButton
              type="button"
              onKeyDown={respondToKeyDown}
              tabIndex={0}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {verb} {title} {chevron}
            </ShowHideButton>
          </ShowHideContent>
        </tr>
        {isExpanded && children}
      </tbody>
    </Table>
  );
};

export default ExpandableTable;
